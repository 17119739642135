import React, { useContext, lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import MainLayout from '../components/Layout/MainLayout';
import ProtectedRoute from './ProtectedRoute';
import LoadingSpinner from '../components/Layout/LoadingSpinner';

const LoginPage = lazy(() => import('../pages/LoginPage'));
const DashboardPage = lazy(() => import('../pages/DashboardPage'));
const CartManagementPage = lazy(() => import('../pages/CartManagementPage'));
const CustomerManagementPage = lazy(() => import('../pages/CustomerManagementPage'));
const VendorManagementPage = lazy(() => import('../pages/VendorManagementPage'));
const OrderManagementPage = lazy(() => import('../pages/OrderManagementPage'));
const InventoryManagementPage = lazy(() => import('../pages/InventoryManagementPage'));
const WarehouseInventory = lazy(() => import('../components/Inventory/WarehouseInventory'));
const PriceManagementPage = lazy(() => import('../pages/PriceManagementPage'));
const AdminManagementPage = lazy(() => import('../pages/AdminManagementPage'));
const AdminProductManagementPage = lazy(() => import('../pages/AdminProductManagementPage'));
const AdminCategoryManagementPage = lazy(() => import('../pages/AdminCategoryManagementPage'));
const WarehouseManagementPage = lazy(() => import('../pages/WarehouseManagementPage'));

const AppRoutes = () => {
  const { isLoggedIn, loading } = useContext(AuthContext);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!isLoggedIn) {
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <Switch>
          <Route exact path="/">
            <LoginPage />
          </Route>
        </Switch>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>
        <ProtectedRoute
          path="/dashboard"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Dashboard']}>
              <DashboardPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/carts"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'MandiKart']}>
              <CartManagementPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/products"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Product']}>
              <AdminProductManagementPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/category"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Category']}>
              <AdminCategoryManagementPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/customers"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Customers']}>
              <CustomerManagementPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/vendors"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Vendor']}>
              <VendorManagementPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/orders"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Orders']}>
              <OrderManagementPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/inventory"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Inventory']}>
              <InventoryManagementPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/inventory/products"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Inventory', 'Product']}>
              <InventoryManagementPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/inventory/products/edit/:productId"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Inventory', 'Product', 'Edit']}>
              <InventoryManagementPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/inventory/warehouse"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Inventory', 'Warehouse Inventory']}>
              <WarehouseInventory {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/warehouses"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'warehouses']}>
              <WarehouseManagementPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/price/management"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Price', 'Management']}>
              <PriceManagementPage {...props} />
            </MainLayout>
          )}
        />
        <ProtectedRoute
          path="/manage"
          component={(props) => (
            <MainLayout breadcrumbItems={['Home', 'Admin', 'Manage']}>
              <AdminManagementPage {...props} />
            </MainLayout>
          )}
        />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;