import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Breadcrumb from './Breadcrumb';
import "./MainLayout.css";

const MainLayout = ({ children, breadcrumbItems }) => {
  return (
    <div className="layout">
      <Sidebar />
      <div className="main">
        <Header />
        <div className="content">
          <Breadcrumb items={breadcrumbItems} />
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
