import React, { createContext, useState, useEffect } from 'react';
import authService from '../services/authService';

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async () => {
    try {
      const userData = await authService.getLoggedInUser();
      if (userData) {
        setUser(userData);
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        setUser(null);
      }
    } catch (error) {
      setIsLoggedIn(false);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) {
      fetchUserData();
    }
  }, [loading]);

  const login = async (phoneNumber, password, role) => {
    try {
      const response = await authService.login(phoneNumber, password, role);
      if (response.success) {
        const userData = { phoneNumber, role, token: response.data.jwt, username: response.data.name, useremail: response.data.email };
        localStorage.setItem('user', JSON.stringify(userData));
        setUser(userData);
        setIsLoggedIn(true);
        setError(null);
        return { success: true };
      } else {
        setError(response.message);
        return { success: false, message: response.message };
      }
    } catch (error) {
      setError('Login failed: Invalid credentials');
      return { success: false, message: 'Login failed: Invalid credentials' };
    }
  };

  const logout = async () => {
    try {
      await authService.logout();
      setIsLoggedIn(false);
      setUser(null);
      localStorage.removeItem('user');
      setError(null);
    } catch (error) {
      setError('Logout failed');
    }
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, login, logout, error, fetchUserData, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;