import axios from 'axios';
import apiConfig from '../config/apiConfig';

// Utility function to make API requests
const authService = {
  login: async (phoneNumber, password, role) => {
    try {
      const response = await axios.post(`${apiConfig.baseURL}${apiConfig.endpoints.auth.login}`, {
        phoneNumber,
        password,
        role,
      });
      return response.data;
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  },

  logout: async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user ? user.token : null;
    try {
      await axios({
        method: 'delete',
        url: `${apiConfig.baseURL}${apiConfig.endpoints.auth.logout}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add your auth token if needed
        },
      });
      localStorage.clear();
      return { success: true };
    } catch (error) {
      console.error('Logout failed:', error);
      throw error;
    }
  },

  getUserRole: async () => {
    try {
      return localStorage.user;
    } catch (error) {
      console.error('Error getting user role:', error);
      throw error;
    }
  },

  getLoggedInUser: async (field = false) => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user) {
        return null;
      }
      return field ? user[field] : user;
    } catch (error) {
      console.error('Error getting logged-in user:', error);
      return null;
    }
  },

  getToken: async () => {
    try {
      const token = await authService.getLoggedInUser('token');
      if (!token) {
        throw new Error('No token found');
      }
      return token;
    } catch (error) {
      console.error('Error getting token:', error);
      throw error;
    }
  }
};

export default authService;