import React from 'react';
import PropTypes from 'prop-types';
import './Breadcrumb.css';

const Breadcrumb = ({ items }) => {
  return (
    <div className="breadcrumb">
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <span>{item}</span>
          {index < items.length - 1 && (
            <span>
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/right-direction-arrow.png`}
                alt="Arrow"
                className="breadcrum-arrow"
              />
            </span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Breadcrumb;