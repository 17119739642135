import React, { createContext, useState } from 'react';

export const WarehouseContext = createContext();

export const WarehouseProvider = ({ children }) => {
  const [selectedWarehouse, setSelectedWarehouse] = useState('');

  return (
    <WarehouseContext.Provider value={{ selectedWarehouse, setSelectedWarehouse }}>
      {children}
    </WarehouseContext.Provider>
  );
};