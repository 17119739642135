import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthContextProvider from './context/AuthContext';
import AppRoutes from './routes/AppRoutes';
import './styles/global.css';
import { WarehouseProvider } from './context/WarehouseContext';
import Modal from 'react-modal';

function App() {
  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  return (
    <Router>
      <AuthContextProvider>
        <WarehouseProvider>
          <AppRoutes />
        </WarehouseProvider>
      </AuthContextProvider>
    </Router>
  );
}

export default App;
