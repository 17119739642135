import React, { useEffect, useState, useContext } from 'react';
import authService from '../../services/authService';
import './Header.css';
import apiConfig from '../../config/apiConfig';
import axios from 'axios';
import { WarehouseContext } from '../../context/WarehouseContext';
import { AuthContext } from '../../context/AuthContext';

const Header = () => {
  const { user } = useContext(AuthContext);
  const [warehouses, setWarehouses] = useState([]);
  const { selectedWarehouse, setSelectedWarehouse } = useContext(WarehouseContext);

  useEffect(() => {
    let isMounted = true;

    const fetchWarehouses = async () => {
      try {
        const token = await authService.getLoggedInUser('token');
        const response = await axios.get(`${apiConfig.baseURL}${apiConfig.endpoints.warehouse.list}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (isMounted) {
          setWarehouses(response.data);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching warehouses:', error);
        }
      }
    };

    if (user.role === 'ADMIN' && warehouses.length === 0) {
      fetchWarehouses();
    }

    return () => {
      isMounted = false;
    };
  }, [user, warehouses.length]);

  const handleWarehouseChange = (event) => {
    const warehouseId = event.target.value;
    setSelectedWarehouse(warehouseId);
  };

  return (
    <header className="header">
      <div className="header-content">
        <div className="header-left">
          {user && (
            <span>
                Welcome <span id='header-username'>{user.username}</span>
                {user.role === 'ADMIN' && (
                  <select className="warehouse-dropdown" value={selectedWarehouse} onChange={handleWarehouseChange}>
                    <option value="">Select Warehouse</option>
                    {warehouses.map((raw) => (
                      <option key={raw.warehouseId} value={raw.warehouseId}>
                        {raw.warehouseName}
                      </option>
                    ))}
                  </select>
                )}
            </span>
          )}
        </div>
        <div className="header-right">
          <img src={`${process.env.PUBLIC_URL}/assets/icons/notification.png`} alt="Notifications" className="notification-icon" />
          <img src={`${process.env.PUBLIC_URL}/assets/icons/avatar.png`} alt="User Avatar" className="avatar-icon" />
        </div>
      </div>
    </header>
  );
};

export default Header;